var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "数据对比新增",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.saveAll("save")
          },
          "head-save-back": function ($event) {
            return _vm.saveAll("back")
          },
          "head-back": _vm.toback,
        },
      }),
      _c(
        "div",
        {
          staticClass: "newZtFormBox",
          staticStyle: { "background-color": "white" },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.stepsActive == 0,
                  expression: "stepsActive == 0",
                },
              ],
              ref: "step1Form",
              attrs: {
                rules: _vm.rules1,
                model: _vm.addform,
                "label-width": "120px",
              },
            },
            [
              _c("div", { staticStyle: { height: "10px" } }),
              _c(
                "el-collapse",
                {
                  staticStyle: { margintop: "10px" },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "1" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "i",
                          {
                            staticClass: "avue-group__title avue-group__header",
                            staticStyle: {
                              color: "#999999 !important",
                              "font-style": "normal",
                              padding: "0 10px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document",
                              staticStyle: {
                                "font-size": "20px",
                                padding: "0 10px",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("cip.dc.offlineDComission.yuaninfo")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("cip.dc.KFDComission.name"),
                                    prop: "name",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addform.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addform, "name", $$v)
                                      },
                                      expression: "addform.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.offlineDComission.sjkid"
                                    ),
                                    prop: "sourceDataSourceId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: _vm.$t(
                                          "OfflinedataCollection.label.Databasename"
                                        ),
                                        error: _vm.$t(
                                          "OfflinedataCollection.label.Databasename"
                                        ),
                                      },
                                      on: { change: _vm.dbChangeFn },
                                      model: {
                                        value: _vm.addform.sourceDataSourceId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addform,
                                            "sourceDataSourceId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addform.sourceDataSourceId",
                                      },
                                    },
                                    _vm._l(_vm.dbList, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.desc,
                                          value: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.offlineDComission.bmid"
                                    ),
                                    prop: "sourceTable",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: _vm.$t(
                                          "OfflinedataCollection.label.Datatablename"
                                        ),
                                        error: _vm.$t(
                                          "OfflinedataCollection.label.Datatablename"
                                        ),
                                      },
                                      on: { change: _vm.getColumnFn },
                                      model: {
                                        value: _vm.addform.sourceTable,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addform,
                                            "sourceTable",
                                            $$v
                                          )
                                        },
                                        expression: "addform.sourceTable",
                                      },
                                    },
                                    _vm._l(
                                      _vm.biaoList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: item.table_name,
                                          attrs: {
                                            label: item.show_name,
                                            value: item.table_name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "主键字段",
                                    prop: "sourceKey",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addform.sourceKey,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addform, "sourceKey", $$v)
                                      },
                                      expression: "addform.sourceKey",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "时间字段",
                                    prop: "sourceDateField",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addform.sourceDateField,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addform,
                                          "sourceDateField",
                                          $$v
                                        )
                                      },
                                      expression: "addform.sourceDateField",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.offlineDComission.incrTimeFormat"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "cip.dc.offlineDComission.incrTimeFormat"
                                        ),
                                        error: _vm.$t(
                                          "OfflinedataCollection.label.Databasename"
                                        ),
                                      },
                                      model: {
                                        value: _vm.addform.sourceDateFieldType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addform,
                                            "sourceDateFieldType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addform.sourceDateFieldType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.TimeTypeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.addform.sourceDateFieldType == 1,
                                  expression:
                                    "addform.sourceDateFieldType == 1",
                                },
                              ],
                              attrs: { span: 8 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.offlineDComission.incrTimeFormat2"
                                    ),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addform.sourceDateFieldType1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addform,
                                          "sourceDateFieldType1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addform.sourceDateFieldType1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.CJudgmentTask.field.CycleSetting"
                                    ),
                                    prop: "cron",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      readonly: "",
                                      value: _vm.addform.cron,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-button-group",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.cronDialogVisible = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "datacenter.dataQuality.monitoringTask.select"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    { on: { click: _vm.changeSee } },
                                    [_vm._v(_vm._s(_vm.seeText))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.seeTen,
                              expression: "seeTen",
                            },
                          ],
                          attrs: { label: _vm.$t("cip.dc.DComission.tenRun") },
                        },
                        [
                          _c(
                            "el-row",
                            _vm._l(_vm.timeList, function (item, index) {
                              return _c(
                                "el-col",
                                { key: index, attrs: { span: "6" } },
                                [_c("div", [_vm._v(_vm._s(item))])]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "2" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "i",
                          {
                            staticClass: "avue-group__title avue-group__header",
                            staticStyle: {
                              color: "#999999 !important",
                              "font-style": "normal",
                              padding: "0 10px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document",
                              staticStyle: {
                                "font-size": "20px",
                                padding: "0 10px",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("cip.dc.offlineDComission.mbinfo")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "OfflinedataCollection.label.Databasename"
                                    ),
                                    prop: "targetDataSourceId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: _vm.$t(
                                          "OfflinedataCollection.label.Databasename"
                                        ),
                                        error: _vm.$t(
                                          "OfflinedataCollection.label.Databasename"
                                        ),
                                      },
                                      on: { change: _vm.dbChangeFn1 },
                                      model: {
                                        value: _vm.addform.targetDataSourceId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addform,
                                            "targetDataSourceId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addform.targetDataSourceId",
                                      },
                                    },
                                    _vm._l(_vm.dbList, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.desc,
                                          value: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "OfflinedataCollection.label.Datatablename"
                                    ),
                                    prop: "targetTable",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: _vm.$t(
                                          "OfflinedataCollection.label.Datatablename"
                                        ),
                                        error: _vm.$t(
                                          "OfflinedataCollection.label.Datatablename"
                                        ),
                                      },
                                      on: { change: _vm.getColumnFn1 },
                                      model: {
                                        value: _vm.addform.targetTable,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addform,
                                            "targetTable",
                                            $$v
                                          )
                                        },
                                        expression: "addform.targetTable",
                                      },
                                    },
                                    _vm._l(
                                      _vm.biaoList1,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.table_name,
                                            value: item.table_name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "主键字段",
                                    prop: "targetKey",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addform.targetKey,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addform, "targetKey", $$v)
                                      },
                                      expression: "addform.targetKey",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "时间字段",
                                    prop: "targetDateField",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addform.targetDateField,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addform,
                                          "targetDateField",
                                          $$v
                                        )
                                      },
                                      expression: "addform.targetDateField",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.offlineDComission.incrTimeFormat"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "cip.dc.offlineDComission.incrTimeFormat"
                                        ),
                                        error: _vm.$t(
                                          "OfflinedataCollection.label.Databasename"
                                        ),
                                      },
                                      model: {
                                        value: _vm.addform.targetDateFieldType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addform,
                                            "targetDateFieldType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addform.targetDateFieldType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.TimeTypeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.addform.targetDateFieldType == 1,
                                  expression:
                                    "addform.targetDateFieldType == 1",
                                },
                              ],
                              attrs: { span: 8 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.offlineDComission.incrTimeFormat2"
                                    ),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addform.targetDateFieldType1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addform,
                                          "targetDateFieldType1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addform.targetDateFieldType1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-tabs",
                { attrs: { type: "border-card" } },
                [
                  _c(
                    "el-tab-pane",
                    {
                      attrs: { label: _vm.$t("cip.dc.offlineDComission.dygx") },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.Autody },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cip.dc.offlineDComission.atuoMatching")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "1" } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "step2Table",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.colmnList,
                                border: "",
                                size: "small",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "55" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t(
                                    "cip.dc.dataobject.field.fieldName"
                                  ),
                                  prop: "name",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t(
                                    "cip.dc.offlineDComission.filedzs"
                                  ),
                                  prop: "common",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t(
                                    "cip.dc.offlineDComission.dygx"
                                  ),
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              filterable: "",
                                            },
                                            model: {
                                              value: scope.row.dygx,
                                              callback: function ($$v) {
                                                _vm.$set(scope.row, "dygx", $$v)
                                              },
                                              expression: "scope.row.dygx",
                                            },
                                          },
                                          _vm._l(
                                            _vm.colmnList1,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("datacenter.dataQuality.monitoringTask.cycleSetting"),
            visible: _vm.cronDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.cronDialogVisible = $event
            },
          },
        },
        [
          _c("cron", {
            staticClass: "cron",
            model: {
              value: _vm.cron,
              callback: function ($$v) {
                _vm.cron = $$v
              },
              expression: "cron",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.cronDialogConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("submitText")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }