<template>
    <div>
      <head-layout
        head-title="数据对比新增"
        :head-btn-options="headBtnOptions"
        @head-save="saveAll('save')"
        @head-save-back="saveAll('back')"
        @head-back="toback"
      >
      </head-layout>
      <div class="newZtFormBox" style="background-color: white">
        <el-form
          ref="step1Form"
          :rules="rules1"
          v-show="stepsActive == 0"
          :model="addform"
          label-width="120px"
        >
          <div style="height: 10px"></div>
  
          <el-collapse v-model="activeNames" style="margintop: 10px">
            <el-collapse-item name="1">
              <template slot="title">
                <i
                  class="avue-group__title avue-group__header"
                  style="
                    color: #999999 !important;
                    font-style: normal;
                    padding: 0 10px;
                  "
                  ><i
                    class="el-icon-document"
                    style="font-size: 20px; padding: 0 10px"
                  ></i>
                  {{ $t('cip.dc.offlineDComission.yuaninfo') }}
                </i>
              </template>
  
              <el-row>
                <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.KFDComission.name')" prop="name">
                    <el-input v-model="addform.name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.offlineDComission.sjkid')" prop="sourceDataSourceId">
                    <el-select
                      filterable
                      @change="dbChangeFn"
                      v-model="addform.sourceDataSourceId"
                      :placeholder="
                        $t('OfflinedataCollection.label.Databasename')
                      "
                      :error="$t('OfflinedataCollection.label.Databasename')"
                    >
                      <el-option
                        v-for="(item, index) in dbList"
                        :key="index"
                        :label="item.desc"
                        :value="item.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.offlineDComission.bmid')" prop="sourceTable">
                    <el-select
                      filterable
                      v-model="addform.sourceTable"
                      @change="getColumnFn"
                      :placeholder="
                        $t('OfflinedataCollection.label.Datatablename')
                      "
                      :error="$t('OfflinedataCollection.label.Datatablename')"
                    >
                      <el-option
                        v-for="(item, index) in biaoList"
                        :key="item.table_name"
                        :label="item.show_name"
                        :value="item.table_name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>






              </el-row>
  
              <el-row>

                <el-col :span="8">
                  <el-form-item label="主键字段" prop="sourceKey">
                    <el-input v-model="addform.sourceKey"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="时间字段" prop="sourceDateField">
                    <el-input v-model="addform.sourceDateField"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                <el-form-item :label="$t('cip.dc.offlineDComission.incrTimeFormat')">
                  <el-select
                    filterable
                    clearable
                    v-model="addform.sourceDateFieldType"
                    :placeholder="$t('cip.dc.offlineDComission.incrTimeFormat')"
                    :error="$t('OfflinedataCollection.label.Databasename')"
                  >
                    <el-option
                      v-for="(item, index) in TimeTypeList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
                
  
                <!-- <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.DComission.startTime')">
                    <el-date-picker
                      clearable
                      v-model="addform.sourceDateField"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      type="datetime"
                      :placeholder="$t('cip.dc.offlineDComission.chooseTime')"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col> -->
  
             
              </el-row>


              <el-row>
              <el-col :span="8" v-show="addform.sourceDateFieldType == 1">
                <el-form-item :label="$t('cip.dc.offlineDComission.incrTimeFormat2')">
                  <el-input v-model="addform.sourceDateFieldType1"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.CJudgmentTask.field.CycleSetting')"
                  prop="cron"
                >
                  <el-input readonly :value="addform.cron"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-button-group>
                  <el-button @click="cronDialogVisible = true">{{
                    $t("datacenter.dataQuality.monitoringTask.select")
                  }}</el-button>
                  <el-button @click="changeSee">{{
                    seeText
                  }}</el-button>
                </el-button-group>
              </el-col>
            </el-row>

             <el-form-item :label="$t('cip.dc.DComission.tenRun')" v-show="seeTen">
              <el-row>
                <el-col span="6" v-for="(item, index) in timeList" :key="index">
                  <div>{{ item }}</div>
                </el-col>
              </el-row>
            </el-form-item>


              
  
             
  
              
  
             
  
  
            </el-collapse-item>
  
            <el-collapse-item name="2">
              <template slot="title">
                <i
                  class="avue-group__title avue-group__header"
                  style="
                    color: #999999 !important;
                    font-style: normal;
                    padding: 0 10px;
                  "
                  ><i
                    class="el-icon-document"
                    style="font-size: 20px; padding: 0 10px"
                  ></i>
                  {{ $t('cip.dc.offlineDComission.mbinfo') }}
                </i>
              </template>
  
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    :label="$t('OfflinedataCollection.label.Databasename')"
                    prop="targetDataSourceId"
                  >
                    <el-select
                      filterable
                      @change="dbChangeFn1"
                      v-model="addform.targetDataSourceId"
                      :placeholder="
                        $t('OfflinedataCollection.label.Databasename')
                      "
                      :error="$t('OfflinedataCollection.label.Databasename')"
                    >
                      <el-option
                        v-for="(item, index) in dbList"
                        :key="index"
                        :label="item.desc"
                        :value="item.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    :label="$t('OfflinedataCollection.label.Datatablename')"
                    prop="targetTable"
                  >
                    <el-select
                      filterable
                      v-model="addform.targetTable"
                      @change="getColumnFn1"
                      :placeholder="
                        $t('OfflinedataCollection.label.Datatablename')
                      "
                      :error="$t('OfflinedataCollection.label.Datatablename')"
                    >
                      <el-option
                        v-for="(item, index) in biaoList1"
                        :key="index"
                        :label="item.table_name"
                        :value="item.table_name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="主键字段" prop="targetKey">
                    <el-input v-model="addform.targetKey"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>

                <el-col :span="8">
                  <el-form-item label="时间字段" prop="targetDateField">
                    <el-input v-model="addform.targetDateField"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                <el-form-item :label="$t('cip.dc.offlineDComission.incrTimeFormat')">
                  <el-select
                    filterable
                    clearable
                    v-model="addform.targetDateFieldType"
                    :placeholder="$t('cip.dc.offlineDComission.incrTimeFormat')"
                    :error="$t('OfflinedataCollection.label.Databasename')"
                  >
                    <el-option
                      v-for="(item, index) in TimeTypeList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>


              <el-col :span="8" v-show="addform.targetDateFieldType == 1">
                <el-form-item :label="$t('cip.dc.offlineDComission.incrTimeFormat2')">
                  <el-input v-model="addform.targetDateFieldType1"></el-input>
                </el-form-item>
              </el-col>


                <!-- <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.DComission.startTime')">
                    <el-date-picker
                      clearable
                      v-model="addform.targetDateField"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      type="datetime"
                      :placeholder="$t('cip.dc.offlineDComission.chooseTime')"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col> -->
              </el-row>
  
              
            </el-collapse-item>
  
            
          </el-collapse>
  
          <el-tabs type="border-card">
            
           
            <el-tab-pane :label="$t('cip.dc.offlineDComission.dygx')">
              <el-button
                      type="primary"
                      @click="Autody"
                      >{{ $t('cip.dc.offlineDComission.atuoMatching') }}</el-button
                    >
              <el-form-item label-width="1">
                <el-table
                  ref="step2Table"
                  :data="colmnList"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldName')"
                    prop="name"
                  >
                  </el-table-column>
  
                  <el-table-column :label="$t('cip.dc.offlineDComission.filedzs')" prop="common">
                  </el-table-column>
  
                  <!-- dataType datetime  timestam-->
  
                  <el-table-column :label="$t('cip.dc.offlineDComission.dygx')">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.dygx" clearable filterable>
                        <el-option
                          :label="item.name"
                          :value="item.name"
                          v-for="(item, index) in colmnList1"
                          :key="index"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </el-form>
      </div>
      <el-dialog
      :title="$t('datacenter.dataQuality.monitoringTask.cycleSetting')"
      :visible.sync="cronDialogVisible"
    >
      <cron v-model="cron" class="cron"></cron>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cronDialogConfirm">{{
          $t("submitText")
        }}</el-button>
      </span>
    </el-dialog>
  
    </div>
  </template>
  
  <script>
  import HeadLayout from "@/views/components/layout/head-layout";
  import cron from "@/views/components/cron";
  import { jxCron } from "@/api/dataAcquisition/index";
  import {
    dataXBuildJson,
    dbListAll,
    getAiModelList,
    getColumn,
    getColumnsByQuerySql,
    syncDb,
  } from "@/api/dataAcquisition/offline";


  import {
    updateDataCompare,
    getcompareInfo,
    SavedataCompare,
  } from "@/api/dataAcquisition/index";
  
  export default {
    components: {
      HeadLayout,
      cron,
    },
    data() {
      return {
        cron:'',
        cronDialogVisible: false,
        activeNames: ["1", "2"],
        seeTen:false,
        seeText:this.$t('cip.dc.offlineDComission.see'),
        headBtnOptions: [
          {
            label: this.$t("cip.cmn.btn.saveBtn"),
            emit: "head-save",
            type: "button",
            icon: "",
            loading: false,
          },
          {
            label: this.$t("cip.cmn.btn.saveBackBtn"),
            emit: "head-save-back",
            type: "button",
            icon: "",
          },
  
          {
            label: this.$t("cip.cmn.btn.celBtn"),
            emit: "head-back",
            type: "button",
            icon: "",
            loading: false,
          },
        ],
  
        addform:{
            name:"",
            sourceDataSourceId:"",
            sourceTable:"",
            sourceDateField:"",
            sourceKey:"",
            targetDataSourceId:"",
            targetTable:"",
            targetDateField:"",
            targetKey:"",
            columnList:[],
            cron:'',
            sourceDateFieldType:'',
            targetDateFieldType:'',
        },


        TimeTypeList: [
       

        {
          label: "yyyy-MM-dd",
          value: "yyyy-MM-dd",
        },

        {
          label: "MM/dd/yyyy HH:mm:ss",
          value: "MM/dd/yyyy HH:mm:ss",
        },
       
        {
          label: this.$t('cip.dc.offlineDComission.other'),
          value: "1",
        },
      ],
       
        step0SaveChecked: [],
        stepsActive: 0,
        showfull: false,
        rules1: {
          name: [{ required: true, message: this.$t('cip.dc.offlineDComission.rule1'), trigger: "blur" }],
          cron: [{ required: true, message: this.$t('cip.dc.offlineDComission.rule2'), trigger: "blur" }],
        },
        
        
       
        dbList: [],
        biaoList: [],
        biaoList1: [],
        colmnList: [],
        colmnList1: [],
        checkAll: false,
        checkedCities: [],
        isIndeterminate: false,
       
      };
    },
    computed: {},
    methods: {
       changeSee(){
      this.seeTen = !this.seeTen
      if(this.seeTen){
        this.seeText = this.$t('cip.dc.offlineDComission.zhedie')
      }else{
        this.seeText = this.$t('cip.dc.offlineDComission.see')
      }
     },
      toback() {
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      },
  
      MjxCron(cron, count) {
        jxCron({ cron: cron, count: count }).then((res) => {
          this.timeList = res.data.data;
        });
      },

      
    cronDialogConfirm() {
      this.addform.cron = this.cron;
      this.cronDialogVisible = false;
      this.timeList = [];
      this.MjxCron(this.cron, 10);
    },
    
     
      changeQueryType(e, a) {
        a.inputValue = "";
        a.startValue = "";
        a.endValue = "";
      },
      upSteps() {
        this.stepsActive--;
        if (this.stepsActive < 0) {
          this.stepsActive = 0;
        }
      },
  
      selectstep0Savetable() {
        console.log("wwwwwwww");
        //保存字段
        // this.step0SaveChecked = this.$refs.step0Savetable.selection;
        // this.addform.readerPareList = [];
        // this.step0SaveChecked.forEach((element) => {
        //   this.addform.readerPareList.push(element.name);
        // });
      },
      nextSteps() {},
      //获取数据库下拉
      getDbTableListFn() {
        dbListAll({}).then((res) => {
          this.dbList = res.data.data;
        });
      },
      dbChangeFn(value) {
        let source = this.dbList.find((item) => item.name == value);
        let obj = {
          host_id: source.name,
          dbType: source.dbtype,
          id: source.db_id,
        };
        this.getAiModelListFn(obj);
      },
      dbChangeFn1(value) {
        let source = this.dbList.find((item) => item.name == value);
        let obj = {
          host_id: source.name,
          dbType: source.dbtype,
          id: source.db_id,
        };
        getAiModelList(obj).then((res) => {
          this.biaoList1 = res.data.data;
        });
      },
      //根据数据库获取表
      getAiModelListFn(obj) {
        getAiModelList(obj).then((res) => {
          this.biaoList = res.data.data;
        });
      },
      getColumnFn(value) {
        let source = this.biaoList.find((item) => item.table_name == value);
        let obj = {
          dbtype: this.addform.sourceDataSourceId,
          host_id: source.host_id,
          name: source.table_name,
        };
        this.dbList.forEach((element) => {
          if (element.name == this.addform.sourceDataSourceId) {
            obj.dbtype = element.dbtype;
          }
        });
        getColumn(obj).then((res) => {
          this.colmnList = res.data.data;
          this.addform.sourceType = 2
          this.colmnList.forEach((item) => {
           
            this.$set(item, "dygx", "");
          });
        });
      },
      handleCheckAllChange(val) {
        this.checkedCities = val ? this.colmnList.map((item) => item.name) : [];
        console.log(this.checkedCities);
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.colmnList.length;
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.colmnList.length;
      },
      getColumnFn1(value) {
        let source = this.biaoList1.find((item) => item.table_name == value);
        let obj = {
          dbtype: this.addform.targetDataSourceId,
          host_id: source.host_id,
          name: source.table_name,
        };
        this.dbList.forEach((element) => {
          if (element.name == this.addform.targetDataSourceId) {
            obj.dbtype = element.dbtype;
          }
        });
        getColumn(obj).then((res) => {
          this.colmnList1 = res.data.data;
          this.colmnList1.forEach((item) => {
            this.$set(item, "sourceColumn", "");
          });
        });
      },
  
      saveAll(e) {
       this.headBtnOptions[0].loading = true
  
       this.addform.columnList = []
  
       
  
        let step2checked = this.$refs.step2Table.selection;
        step2checked.forEach((element) => {
          this.addform.columnList.push({
            sourceColumn: element.name,
            targetColumn: element.dygx,
          });
        });
  
        if (this.addform.columnList.length > 0) {
          let canSave = true;
          this.addform.columnList.forEach((element) => {
            if (!element.targetColumn) {
              canSave = false;
            }
          });
  
          if (!canSave) {
            this.$message.warning(this.$t('cip.dc.offlineDComission.savetip'));
            this.headBtnOptions[0].loading = false
            return;
          }
        }
  
        let parms = {
            name:this.addform.name,
            sourceDataSourceId:this.addform.sourceDataSourceId,
            sourceTable:this.addform.sourceTable,
            sourceDateField:this.addform.sourceDateField,
            sourceKey:this.addform.sourceKey,
            targetDataSourceId:this.addform.targetDataSourceId,
            targetTable:this.addform.targetTable,
            targetDateField:this.addform.targetDateField,
            targetKey:this.addform.targetKey,
            columnList:this.addform.columnList,
         
        };
      
  
    
  
        if (this.$route.query.id) {
          parms.id = this.$route.query.id;
          updateDataCompare(parms)
            .then((res) => {
              this.$message.success(this.$t('cip.dc.offlineDComission.editmsg'));
              this.headBtnOptions[0].loading = false
              if (e == "back") {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              }
            })
            .catch((res) => {
              this.headBtnOptions[0].loading = false
            });
        } else {
          SavedataCompare(parms)
            .then((res) => {
              this.$message.success(this.$t('cip.dc.offlineDComission.savemsg')
  );
              if (e == "back") {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              }
              this.headBtnOptions[0].loading = false
            })
            .catch((res) => {
              this.headBtnOptions[0].loading = false
            });
        }
      },
  
    //匹配对应关系
      Autody(){
        this.colmnList.forEach(item1 => {
          this.colmnList1.forEach(item2 => {
            if(item1.name==item2.name){
                item1.dygx=item2.name
            }
          });
        });
      },
  
  
  
      
      getColumnsByQuerySqlFn() {
        let obj = {
          querySql: this.addform.rdbmsReaderBo.querySql,
          datasourceId: this.addform.sourceDataSourceId,
        };
  
        getColumnsByQuerySql(obj).then((res) => {
          this.$message.success(this.$t('cip.dc.offlineDComission.jieximsg'));
          this.$refs.step0Savetable.clearSelection();
          this.checkedCities = res.data.data;
          this.colmnList = res.data.data;
          this.addform.sourceType = 1
         
  
        
  
          
         
        });
      },
    },
    created() {
      this.getDbTableListFn();
      // this.other = this.$route.query.other;
    },
    mounted() {
      if (this.$route.query.type == "edit") {
        getcompareInfo({ id: this.$route.query.id }).then((res) => {
          this.addform = res.data.data;
  
          this.addform.columnList = res.data.data.columnList;
       
        
          dbListAll({}).then((res) => {
            this.dbList = res.data.data;
  
            let source = this.dbList.find(
              (item) => item.name == this.addform.sourceDataSourceId
            );
            let obj = {
              host_id: source.name,
              dbType: source.dbtype,
              id: source.db_id,
            };
  
            getAiModelList(obj).then((res) => {
              this.biaoList = res.data.data;
              let source = this.biaoList.find(
                (item) => item.show_name == this.addform.sourceTable
              );
              this.addform.sourceTable = source.table_name;
              let obj = {
                dbtype: this.addform.sourceDataSourceId,
                host_id: source.host_id,
                name: source.table_name,
              };
  
              this.dbList.forEach((element) => {
                if (element.name == this.addform.sourceDataSourceId) {
                  obj.dbtype = element.dbtype;
                }
              });
  
              getColumn(obj).then((res) => {
                this.colmnList = res.data.data;
                this.colmnList.forEach((item) => {
                  this.$set(item, "dygx", "");
                });
                //回显保存字段
                this.$nextTick(() => {
                  this.colmnList.forEach((element, index) => {
                    this.addform.columnList.forEach((element2) => {
                      if (element.name == element2.sourceColumn) {
                        element.dygx = element2.targetColumn;
                        this.$refs.step2Table.toggleRowSelection(
                          this.colmnList[index],
                          true
                        );
                      }
                    });
                  });
                  
                });
              });
            });
  
            let source2 = this.dbList.find(
              (item) => item.name == this.addform.targetDataSourceId
            );
            let obj2 = {
              host_id: source2.name,
              dbType: source2.dbtype,
              id: source2.db_id,
            };
            getAiModelList(obj2).then((res) => {
              this.biaoList1 = res.data.data;
              let source = this.biaoList1.find(
                (item) => item.table_name == this.addform.targetTable
              );
              let obj = {
                dbtype: this.addform.targetDataSourceId,
                host_id: source.host_id,
                name: source.table_name,
              };
              this.dbList.forEach((element) => {
                if (element.name == this.addform.targetDataSourceId) {
                  obj.dbtype = element.dbtype;
                }
              });
              getColumn(obj).then((res) => {
                this.colmnList1 = res.data.data;
               
              });
            });
  
           
            
          });
        });
      }
  

    },
  };
  </script>
  <style></style>
  